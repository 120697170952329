<template>
  <FinestForum></FinestForum>
</template>
<script>
import FinestForum from '@/components/h5/FinestForum' 
export default {
  components:{
    FinestForum,
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped></style>
