<template>
  <div class="play">
    <!-- 返回、标题 -->
    <div class="play-header" :style="{ paddingTop: 35 + statusBarHeight + 'px' }">
      <Back 
        :clickHandle="handleClosePage"
        :innerText="localesPackage?.['yuli_back']?.[localesEnv]"
      />
    </div>
    
  </div>
</template>
<script>
import { mapState } from "vuex";
import { onBeforeMount, onMounted, ref, nextTick } from "vue";
import appBridging from "@/utils/appBridging.js";
import {getChipHowToPlayLanguage} from "@/api/language.js";
import store from "@/store";
import Back from "@/components/universal/Back";

export default {
  components: {
    Back
  },
  computed: {
    ...mapState({
      init: (state) => state.global.init,
      statusBarHeight: (state) => state.global.statusBarHeight,
      languageEnv: (state) => state.global.languageEnv,
      languagePackage: (state) => state.global.languagePackage,
    }),
  },
  watch: {
    languageEnv: {
      async handler(value) {
        console.log('languageEnv: ', value);
        if(value){
          this.localesEnv = value;
        }
      },
      immediate: true,
      deep: true,
    },
    languagePackage: {
      async handler(value) {
        console.log('languagePackage: ', value);
        if(value){
          this.localesPackage = value;
        }
      },
      immediate: true,
      deep: true,
    }
  },
  setup() {
    /**语言包 - ref */
    const localesPackage = ref({});
    /**语言环境 - ref */
    const localesEnv = ref('en');

    onBeforeMount(async ()=>{
      appBridging.getSettingLaungage();
      const res = await getChipHowToPlayLanguage();
      store.dispatch("global/setLanguagePackage", res);// 存到stroe
    });

    onMounted(() => {
      appBridging.getStatusBarHeight();
      appBridging.closeLoading();
    });

    /**
     * 关闭页面
     */
    const handleClosePage = () => {
      appBridging.closePage();
    };
    return {
      handleClosePage,
      localesPackage,
      localesEnv
    };
  },
};
</script>
<style lang="less" scoped>
h1,
p {
  padding: 0;
  margin: 0;
}
.play {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  // background: linear-gradient(180deg, #352d62ff 0%, #2d64a5ff 100%);
  background-image: url("~@/assets/images/YuliApp/finest/bg.png");
  // background-size: 100% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  &-header {
    position: relative;
    width: 100%;
    height: 30px;
    // background-image: url("~@/assets/images/YuliApp/howToPlay/header_bg.png");
    // background-size: 100% 100%;

    &-block {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 30px;
      &-title {
        position: absolute;
        // left: 50%;
        top: 0;
        // transform: translate(-50%, 0);
        right: 15px;
        height: 30px;
        color: rgba(255, 255, 255, 1);
        font-size: 24px;
        font-weight: 500;
        font-family: "PingFang SC";
        text-align: right;
        line-height: 28.8px;
      }
    }
  }
  
}
</style>
